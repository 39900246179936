@font-face {
  font-family: Circular Std;
  src: url("./assets/fonts/circularstd-book.otf") format("opentype");
}

* {
  box-sizing: border-box;
}

body {
  overscroll-behavior: contain;
  margin: 0;
}

.swal2-container {
  z-index: 1400 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.no-autofill input:-webkit-autofill,
.no-autofill input:-webkit-autofill:hover,
.no-autofill input:-webkit-autofill:focus,
.no-autofill input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #192254 inset !important;
  box-shadow: 0 0 0 30px #192254 inset !important;
  -webkit-text-fill-color: white !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw900 {
  font-weight: 900 !important;
}

.ls1 {
  letter-spacing: 1px;
}

.swal2-title {
  font-size: 38px;
  line-height: 125%;
}